@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-container {
  @apply px-5 lg:px-44 pt-2;
}


.enroll-button {
  @apply bg-gradient-to-r from-wallstreet-primary via-wallstreet-black to-wallstreet-foreground;
  @apply px-5 py-3 rounded-lg font-semibold;
}
.send-button {
  @apply bg-gradient-to-r from-wallstreet-primary to-wallstreet-foreground;
  @apply px-8 py-3 rounded-lg font-semibold  text-wallstreet-white;
}

.bg-gradient {
  @apply bg-gradient-to-b from-wallstreet-primary to-wallstreet-black;
}